class hostForLocation {
    static getLocationForHost(_hostLocation, backend) {
        // _hostLocation = 'ooh.marketer.lv'
        let hostLoction = _hostLocation;

        let analyticsHost = _hostLocation;
        let mainHost = _hostLocation;
        if (_hostLocation === 'localhost:8080') {
            hostLoction = 'client.getshopster.net';

            analyticsHost = 'client.getshopster.net';
            mainHost = 'client.getshopster.net';
        }

        
        this['host'] = {
            // analytics: `https://analytics-service.v1.${analyticsHost}`,
            analytics: `https://analytics-service.v2.${analyticsHost}`,
            admin: `https://admin.${hostLoction}`,
            maps: `https://maps.${hostLoction}`,
            api: `https://api.getshopster.net`,
            export: `https://export.${mainHost}`,
            cms: `https://cms.${mainHost}`,
            precampaign: `https://pre-campaign.${mainHost}`,
            clientGetShopster: `https://${hostLoction}`,
            clientCloudGetshopster: `https://${mainHost}`,
            mainGetShopster: `https://${mainHost}`,
            outdoorMapsGetShopster: `https://outdoor-maps.${mainHost}`,
            pageConstructorGetshopster: `https://pageconstructor.${mainHost}`,
            clientDevGetShopster: `https://client.dev.${mainHost}`,
            ssoWifiZoneMe: `https://sso.${mainHost}`,
            oohMarketerLv: `https://ooh.marketer.lv`


        };
        console.log('sfsgsfdgsgs', _hostLocation)
        if (backend !== undefined && backend.checks && Object.keys(backend).length !== 0) {
            // console.log(backend);
            Object.keys(backend.checks).forEach((key) => {
                if (backend.checks && backend.checks[key]) {

                    this['host'][key] = backend[key];
                }
            });

            
        }

    
    }

    static getHostName() {
        
        return this.host;
    }
    // static getHostNameAdmin () {
    //     return this.host.analytics;
    // }
    // static getHostNameMaps () {
    //     return this.host.maps;
    // }
    // static getHostNameApi () {
    //     return this.host.api;
    // }
}

export default hostForLocation;
